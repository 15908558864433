// extracted by mini-css-extract-plugin
export var aboutOutline = "contact-module--aboutOutline--ce7a6";
export var activeLink = "contact-module--activeLink--10043";
export var center = "contact-module--center--bc586";
export var contact = "contact-module--contact--f3415";
export var contactOutline = "contact-module--contactOutline--c00bc";
export var corner = "contact-module--corner--b418f";
export var emailLink = "contact-module--emailLink--c4b25";
export var footerTop = "contact-module--footerTop--5cfde";
export var gridContainer = "contact-module--gridContainer--0087a";
export var instagram = "contact-module--instagram--5b368";
export var instagramLink = "contact-module--instagramLink--e9cea";
export var layoutContainer = "contact-module--layoutContainer--73856";
export var link = "contact-module--link--f82ad";
export var navLink = "contact-module--navLink--2ed7c";
export var projectsOutline = "contact-module--projectsOutline--b9ad3";
export var sidebar___contact = "contact-module--sidebar___contact--fb137";