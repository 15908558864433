// extracted by mini-css-extract-plugin
export var a = "hamburgerMenu-module--a--8a932";
export var aboutOutline = "hamburgerMenu-module--aboutOutline--81d5d";
export var contactOutline = "hamburgerMenu-module--contactOutline--7109f";
export var gridContainer = "hamburgerMenu-module--gridContainer--dcc3b";
export var hamburger = "hamburgerMenu-module--hamburger--32a0a";
export var icon = "hamburgerMenu-module--icon--5d3e7";
export var layoutContainer = "hamburgerMenu-module--layoutContainer--b0b78";
export var li = "hamburgerMenu-module--li--8d9a7";
export var link = "hamburgerMenu-module--link--b2bb0";
export var menuOpen = "hamburgerMenu-module--menuOpen--192d0";
export var nav = "hamburgerMenu-module--nav--d6f14";
export var projectsOutline = "hamburgerMenu-module--projectsOutline--1b425";
export var ul = "hamburgerMenu-module--ul--8903b";